import React from "react";
import styled from "styled-components";
import { StaticQuery } from "gatsby";

// Components
import ServicesNav from "./services-nav";
import Service from "./service";

/**
 * Style
 */
const StyledServices = styled.section`
  .Services__List {
    margin-top: 60px;
  }

  /** Responsive */
  @media (max-width: 991px) {
    .Services__List {
      margin-top: 0;
    }
  }
`;

/**
 * Component
 */
const Services = () => {
  return (
    <StaticQuery
      query={graphql`
        {
          wpPage(slug: { eq: "services" }) {
            services {
              fieldGroupName
              ... on WpPage_Services {
                services {
                  serviceTitle
                  serviceSecondTitle
                  serviceDescription
                  serviceIconName

                  serviceKeys {
                    serviceKeyIconClass
                    serviceKeyTitle
                  }

                  serviceToolsTechnologies {
                    ... on WpPage_Services_services_ServiceToolsTechnologies {
                      toolsTechUserExperience
                      toolsTechCloudServices
                      toolsTechProjectManagement
                      toolsTechWebDevelopment
                      toolsTechMobileDevelopment
                      toolsTechQualityAssurance
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        const {
          wpPage: { services: servicesList },
        } = data;

        return (
          <StyledServices className="Section Services">
            <div className="Container">
              {/* Title */}
              <div className="Section__Headline Text--center">
                <h2 className="Text--small Opacity--40 FontWeight--bold Text--uppercase">
                  Services
                </h2>
                <p className="Text--xlarge FontWeight--bold">
                  Highlights of what we can deliver
                </p>
              </div>

              {/* Navigation */}
              <ServicesNav services={servicesList.services} />

              {/* List */}
              <div className="Services__List">
                {servicesList.services.map((service, index) => {
                  return (
                    <Service
                      key={`serviceItem${index}`}
                      service={service}
                    />
                  );
                })}
              </div>
            </div>
          </StyledServices>
        );
      }}
    ></StaticQuery>
  );
};

export default Services;
