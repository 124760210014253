import React, { useState } from "react";
import styled from "styled-components";

// Utils
import colors from "../../../styles/utils/colors";

/**
 * Style
 */
const StyledService = styled.div`
  display: flex;
  border: 1px solid ${colors.light.light200};
  justify-content: space-between;
  margin-bottom: 30px;

  h5 {
    font-size: 20px;
    margin-bottom: 20px;

    small {
      display: block;
      font-size: 13px;
      margin-bottom: 10px;
      text-transform: uppercase;
    }
  }

  .Services__List__Content {
    padding: 30px;
    width: 66.66667%;

    .Btn {
      margin-top: 20px;
    }

    .Services__List__Keys {
      margin-top: 30px;

      ul {
        display: flex;
        flex-wrap: wrap;

        li {
          flex: 50%;
          margin-bottom: 15px;

          img {
            height: 30px;
            margin-right: 10px;
            width: 30px;
          }
        }
      }
    }

    .Services__List__Tools {
      margin-top: 30px;

      h6 {
        font-size: 14px;
        margin-bottom: 15px;
      }

      ul {
        display: flex;
        flex-wrap: wrap;

        li {
          background: ${colors.light.light100};
          border: 1px solid ${colors.light.white};
          padding: 25px 0;
          text-align: center;
          width: 25%;

          span {
            display: block;
          }

          img {
            max-width: 100px;
            width: 50%;
          }
        }
      }
    }
  }

  .Services__List__Picture {
    display: flex;
    align-items: center;
    background: ${colors.light.light150};
    justify-content: center;
    width: 33.33333%;
  }

  .DropDown__Content {
    height: 0;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear;

    &.open {
      height: auto;
      visibility: visible;
      opacity: 1;
    }
  }

  .Btn {
    cursor: pointer;
    border: 1px solid ${colors.primary.primary800};

    &.Btn--open {
      background: ${colors.primary.primary800};
      color: ${colors.light.white};
      border: none;
    }
  }

  /* Responsive */
  @media (max-width: 991px) {
    flex-wrap: wrap;

    .Services__List__Picture {
      min-height: 180px;
      width: 100%;
    }

    .Services__List__Content {
      width: 100%;

      .Services__List__Keys {
        ul li {
          font-size: 13px;
          text-align: center;
        }

        span {
          display: block;

          &:first-child {
            margin-bottom: 10px;
          }

          &:last-child {
            display: inline-block;
            width: 80%;
          }
        }
      }

      .Services__List__Tools ul li {
        width: 50%;
      }

      .Btn {
        width: 100%;
      }
    }
  }
`;

/**
 * Component
 */
const Service = ({ service }) => {
  // States
  const [isOpen, setIsOpen] = useState(false);

  //
  const tools =
    service.serviceToolsTechnologies[
      `toolsTech${service.serviceTitle.replace(" ", "")}`
    ];

  //
  return (
    <StyledService className="Services__List__Element">
      {/* Picture */}
      <div className="Services__List__Picture">
        <img
          src={`/images/services/${service.serviceIconName}.svg`}
        />
      </div>

      {/* Content */}
      <div className="Services__List__Content DropDown">
        <h5>
          <small>{service.serviceTitle}</small>
          {service.serviceSecondTitle}
        </h5>
        <p>{service.serviceDescription}</p>

        <div
          className={`DropDown__Content Services__List__More ${
            isOpen ? "open" : ""
          }`}
        >
          {/* Services List Keys */}
          {service.serviceKeys !== null ? (
            <div className="Services__List__Keys">
              <ul>
                {service.serviceKeys.map((activity, index) => {
                  return (
                    <li key={`activity-${index}`}>
                      <span>
                        <img
                          src={`/images/services/${service.serviceIconName}/${activity.serviceKeyIconClass}.svg`}
                          alt=""
                        />
                      </span>
                      <span>{activity.serviceKeyTitle}</span>
                    </li>
                  );
                })}
              </ul>
            </div>
          ) : (
            ""
          )}

          {/* Tools & Technologies */}
          {tools ? (
            <div className="Services__List__Tools">
              <h6>Tools &amp; Technologies</h6>
              <ul>
                {tools.map((tool, index) => {
                  return (
                    <li key={`tools-${index}`}>
                      <span>
                        <img
                          src={`/images/services/tools-tech/${tool}.png`}
                          alt=""
                        />
                      </span>
                      <span>{tool}</span>
                    </li>
                  );
                })}
              </ul>
            </div>
          ) : (
            ""
          )}
        </div>

        <span
          className={`Btn Btn--medium Display--inline Text--small DropDown__Btn ${
            isOpen ? "Btn--open" : ""
          }`}
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          {isOpen ? "Hide" : "Show"}
        </span>
      </div>
    </StyledService>
  );
};

export default Service;
