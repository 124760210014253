import React from "react";
import styled from "styled-components";
import { Formik } from "formik";
import * as Yup from "yup";

// Utils
import colors from "../../../styles/utils/colors";

// Helpers
import {
  encode,
  getCareerFormFields,
  checkIfFileIsTooBig,
  checkIfFileHasCorrectType,
} from "../../../helpers/helpers";

/**
 * Style
 */
const StyledCareerDetailForm = styled.div`
  margin-top: 3rem;
  padding: 2rem 0;
  border-top: 1px solid ${colors.light.light165};

  h2 {
    font-size: 3.3rem;
    text-align: center;
    margin-bottom: 4rem;
  }

  .Container {
    padding: 0 10vw !important;
  }

  .Form__field {
    position: relative;
    width: 100%;
    max-width: 100%;

    margin-left: 0;
    margin-right: 0;
    margin-bottom: 2.3rem;

    label {
      display: block;
      margin: 0.5rem 0;

      text-transform: none;
      text-align: left;

      font-size: 1.2rem;
      font-weight: 700;
      line-height: 1.28571429;
      letter-spacing: 0.2px;

      .Asterisk {
        margin-left: 5px;
        font-size: 18px;
        line-height: 18px;
        color: ${colors.messages.error};
      }
    }

    input:not([type="file"]) {
      width: 100%;
      max-width: 100%;
      padding: 1.15rem 1rem;
      margin-left: 0;

      border-radius: 4px;

      font-size: 1.4rem;
      font-weight: 400;
      line-height: 1.25;
      letter-spacing: normal;

      box-sizing: border-box;
      background-color: ${colors.light.white};
      color: ${colors.brown};
      border: 1px solid ${colors.light.light700};
    }
  }

  .Form__button {
    display: block;
    padding: 19px 40px 20px;
    width: 100%;

    border: none;
    cursor: pointer;
    border-radius: 4px;

    text-align: center;
    text-transform: uppercase;
    text-decoration: none;

    font-size: 1rem;
    font-weight: 700;

    line-height: 1.28571429;
    letter-spacing: 0.8px;

    transition: box-shadow 420ms cubic-bezier(0.165, 0.84, 0.44, 1),
      color 420ms cubic-bezier(0.165, 0.84, 0.44, 1),
      background 420ms cubic-bezier(0.165, 0.84, 0.44, 1);

    background: ${colors.primary.primary700};
    color: ${colors.light.white};

    &:hover {
      background: ${colors.primary.primary800};
    }
  }
`;

/**
 * Component
 */
const CareerDetailForm = ({ setThankYou, category, formName }) => {
  // Validation Schema
  const ApplyFormSchema = Yup.object().shape({
    firstName: Yup.string().required().min(3).max(50),
    lastName: Yup.string().required().min(3).max(50),
    email: Yup.string().email().required().max(50),
    phone: Yup.string().required().max(50),
    location: Yup.string().required().min(4).max(50),
    resume: Yup.mixed()
      .required("CV Required")
      .test(
        "is-correct-type",
        "Wrong CV Type (pdf only)",
        checkIfFileHasCorrectType,
      )
      .test(
        "is-big-file",
        "File too large - 2MB Maximum",
        checkIfFileIsTooBig,
      ),
    linkedinProfile: Yup.string().url().required(),

    // Designer
    dribbble: Yup.string().url(),
    behance:
      category === "Design"
        ? Yup.string().url().required()
        : Yup.string().url().notRequired(),

    // Engineer
    github:
      category === "Engineering"
        ? Yup.string().url().required()
        : Yup.string().url().notRequired(),
    website: Yup.string().url(),
  });

  /** View */
  return (
    <StyledCareerDetailForm id="apply" className="CareerDetail__form">
      <div className="Container">
        <h2>Apply for this Job</h2>

        <Formik
          initialValues={getCareerFormFields(category)}
          validationSchema={ApplyFormSchema}
          onSubmit={(values, actions) => {
            const encodedFormData = encode({
              "form-name": event.target.getAttribute("name"),
              ...values,
            });

            fetch("/", {
              method: "POST",
              body: encodedFormData,
            })
              .then(() => {
                setThankYou({
                  stat: true,
                  firstName: values.firstName,
                });
              })
              .catch((error) => console.log(error))
              .finally(() => actions.setSubmitting(false));
          }}
        >
          {({
            values,
            setFieldValue,
            dirty,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => {
            return (
              <form
                name={`Career - ${formName}`}
                //
                data-netlify={true}
                data-netlify-honeypot="bot-field"
                //
                onSubmit={handleSubmit}
                method="POST"
                noValidate
              >
                {/* First Name */}
                <div className="Form__field">
                  <label htmlFor="firstName">
                    First Name
                    <span className="Asterisk">*</span>
                  </label>
                  <input
                    type="text"
                    name="firstName"
                    autoComplete="given-name"
                    //
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.firstName}
                  />

                  {/* Error Message */}
                  {errors.firstName && touched.firstName && (
                    <div className="Form__error">
                      {errors.firstName}
                    </div>
                  )}
                </div>

                {/* Last Name */}
                <div className="Form__field">
                  <label htmlFor="lastName">
                    Last Name
                    <span className="Asterisk">*</span>
                  </label>
                  <input
                    type="text"
                    name="lastName"
                    autoComplete="family-name"
                    //
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.lastName}
                  />

                  {/* Error Message */}
                  {errors.lastName && touched.lastName && (
                    <div className="Form__error">
                      {errors.lastName}
                    </div>
                  )}
                </div>

                {/* Email */}
                <div className="Form__field">
                  <label htmlFor="email">
                    Email
                    <span className="Asterisk">*</span>
                  </label>
                  <input
                    type="email"
                    placeholder=""
                    name="email"
                    autoComplete="email"
                    //
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                  />

                  {/* Error Message */}
                  {errors.email && touched.email && (
                    <div className="Form__error">{errors.email}</div>
                  )}
                </div>

                {/* Phone */}
                <div className="Form__field">
                  <label htmlFor="phone">
                    Phone
                    <span className="Asterisk">*</span>
                  </label>
                  <input
                    type="tel"
                    name="phone"
                    autoComplete="tel"
                    placeholder="eg: 0666-11-22-33"
                    //
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phone}
                  />

                  {/* Error Message */}
                  {errors.phone && touched.phone && (
                    <div className="Form__error">{errors.phone}</div>
                  )}
                </div>

                {/* Location */}
                <div className="Form__field">
                  <label htmlFor="location">
                    Location(City)
                    <span className="Asterisk">*</span>
                  </label>
                  <input
                    type="text"
                    name="location"
                    placeholder="eg: Agadir"
                    //
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.location}
                  />

                  {/* Error Message */}
                  {errors.location && touched.location && (
                    <div className="Form__error">
                      {errors.location}
                    </div>
                  )}
                </div>

                {/* Resume / CV */}
                <div className="Form__field">
                  <label htmlFor="resume">
                    Resume/CV (pdf)
                    <span className="Asterisk">*</span>
                  </label>
                  <input
                    type="file"
                    name="resume"
                    accept="application/pdf"
                    //
                    onChange={(event) => {
                      setFieldValue(
                        "resume",
                        event.currentTarget.files[0],
                      );
                    }}
                    onBlur={handleBlur}
                  />
                  {/* Error Message */}
                  {errors.resume && touched.resume && (
                    <div className="Form__error">{errors.resume}</div>
                  )}
                </div>

                {/* LinkedIn Profile */}
                <div className="Form__field">
                  <label htmlFor="linkedinProfile">
                    LinkedIn Profile
                    <span className="Asterisk">*</span>
                  </label>
                  <input
                    type="url"
                    id="linkedinProfile"
                    name="linkedinProfile"
                    placeholder="https://linkedin.com/in/username"
                    //
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.linkedinProfile}
                  />

                  {/* Error Message */}
                  {errors.linkedinProfile &&
                    touched.linkedinProfile && (
                      <div className="Form__error">
                        {errors.linkedinProfile}
                      </div>
                    )}
                </div>

                {/* Show Theses Fields if only the form is aimed to Design Careers */}
                {category === "Design" ? (
                  <>
                    {/* Behance */}
                    <div className="Form__field">
                      <label htmlFor="behance">
                        Behance
                        <span className="Asterisk">*</span>
                      </label>
                      <input
                        type="url"
                        id="behance"
                        name="behance"
                        placeholder="https://www.behance.net/username"
                        //
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.behance}
                      />

                      {/* Error Message */}
                      {errors.behance && touched.behance && (
                        <div className="Form__error">
                          {errors.behance}
                        </div>
                      )}
                    </div>

                    {/* Dribbble */}
                    <div className="Form__field">
                      <label htmlFor="dribbble">Dribbble</label>
                      <input
                        type="url"
                        id="dribbble"
                        name="dribbble"
                        placeholder="https://dribbble.com/username"
                        //
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.dribbble}
                      />

                      {/* Error Message */}
                      {errors.dribbble && touched.dribbble && (
                        <div className="Form__error">
                          {errors.dribbble}
                        </div>
                      )}
                    </div>
                  </>
                ) : (
                  ""
                )}

                {/* Show These Fields if only the form is aimed to Engineering Careers */}
                {category === "Engineering" ? (
                  <>
                    {/* Github */}
                    <div className="Form__field">
                      <label htmlFor="wesbite">
                        Github
                        <span className="Asterisk">*</span>
                      </label>
                      <input
                        type="url"
                        id="github"
                        name="github"
                        placeholder="https://github.com/username"
                        //
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.github}
                      />

                      {/* Error Message */}
                      {errors.github && touched.github && (
                        <div className="Form__error">
                          {errors.github}
                        </div>
                      )}
                    </div>

                    {/* Website */}
                    <div className="Form__field">
                      <label htmlFor="wesbite">Web Site</label>
                      <input
                        type="url"
                        id="website"
                        name="website"
                        placeholder="https://example.com"
                        //
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.website}
                      />

                      {/* Error Message */}
                      {errors.website && touched.website && (
                        <div className="Form__error">
                          {errors.website}
                        </div>
                      )}
                    </div>
                  </>
                ) : (
                  ""
                )}

                {/* Button */}
                <button
                  type="submit"
                  className={`Form__button ${
                    !dirty || isSubmitting ? "Btn__not-allowed" : ""
                  }`}
                  disabled={!dirty || isSubmitting}
                >
                  Submit Application
                </button>
              </form>
            );
          }}
        </Formik>
      </div>
    </StyledCareerDetailForm>
  );
};

export default CareerDetailForm;
