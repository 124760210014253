import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

// Utils
import colors from "../../../styles/utils/colors";

/**
 * Style
 */
const StyledCareerDetailNav = styled.nav`
  &.CareerDetail {
    padding: 2rem 1rem;
    border-bottom: 1px solid ${colors.light.light165};
    margin-bottom: 25px;
  }

  .CareerDetail__nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .CareerDetail-location {
    margin-right: 0;
    text-align: right;
  }

  .CareerDetail-location p {
    display: inline-block;
    margin-bottom: 0;
  }

  .CareerDetail__nav-secondary .apply-desktop {
    display: none;
  }

  .arrow-icon {
    width: 1rem;
    height: 20px;
    vertical-align: text-bottom;
    fill: currentColor;
  }

  /** Button */
  .Btn  {
    &.Btn--to-link {
      min-width: auto;
      padding: 0;

      box-shadow: none;
      background-color: transparent;
      border: 0;
    }
  }

  .CareerDetail__nav .Btn {
    width: auto;
  }

  /** Responsive */

  @media screen and (max-width: 30rem) {
    .Btn {
      &.Btn--to-link {
        &:hover {
          background: none;
        }
      }
    }
  }

  @media screen and (min-width: 48rem) {
    .CareerDetail__nav-secondary .apply-desktop {
      display: inline-block !important;
    }

    .CareerDetail-location {
      margin-right: 30px;
    }

    /** Button */
    .Btn {
      display: inline-block;
      white-space: nowrap;
      flex-basis: auto;
      width: auto;

      padding: 7px 0 !important;
      border-radius: 6px;
    }

    .Btn--outline.Btn--outline--dark.Btn--to-link {
      min-width: 140px;
      padding: 0 30px;

      border: 1px solid ${colors.primary.primary800};

      &:hover {
        background: ${colors.primary.primary800};
        color: ${colors.light.white};
      }
    }

    .CareerDetail__nav .Btn {
      width: auto;
    }
  }
`;

/**
 * Component
 */
const CareerDetailNav = ({ category }) => {
  return (
    <StyledCareerDetailNav className="CareerDetail Container">
      <div className="CareerDetail__nav">
        <Link
          to="/careers"
          className="Btn Btn--medium Btn--outline Btn--outline--dark Btn--to-link"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="-288 379 35 35"
            width="20"
            height="20"
            className="arrow-icon svg-replaced"
            shapeRendering="geometricPrecision"
          >
            <path d="M-284.5 397.5l9.9 10.1c.6.6 1.5.6 2 0 .6-.6.6-1.5 0-2.1l-7.5-7.6h22.4c.8 0 1.4-.7 1.4-1.5s-.6-1.5-1.4-1.5H-280l7.5-7.6c.6-.6.6-1.5 0-2.1-.6-.6-1.5-.6-2 0l-9.9 10.1c-.6.7-.6 1.7-.1 2.2z"></path>
          </svg>{" "}
          All listings
        </Link>
        <div className="CareerDetail__nav-secondary">
          <span className="CareerDetail-location">
            <p>{category}, Casablanca</p>
          </span>
          <a
            href="#apply"
            className="Btn Btn--medium Btn--dark apply-desktop"
          >
            Apply
          </a>
        </div>
      </div>
    </StyledCareerDetailNav>
  );
};

export default CareerDetailNav;
