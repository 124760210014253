import React from "react";
import { useState } from "react";
import styled from "styled-components";
import { Link } from "gatsby";

// Utils
import colors from "../../styles/utils/colors";

/**
 * Styled
 */
const StyledHeader = styled.header`
  position: relative;

  background: linear-gradient(
      90deg,
      ${colors.primary.primary500},
      ${colors.primary.primary700} 54%,
      ${colors.primary.primary600}
    )
    50% no-repeat;

  font-size: 14px;
  height: 100px;
  z-index: 99;

  .Container--Header {
    display: flex;
    align-items: stretch;
    flex-direction: row;

    > * {
      flex-grow: 1;
    }
  }

  .Nav__Brand {
    margin-top: 35px;

    svg {
      fill: ${colors.light.white};
    }
  }

  .Nav__Collapse {
    display: none;
    position: relative;

    .Nav__Collapse__Btn {
      cursor: pointer;
      height: 40px;
      position: absolute;
      right: 0;
      text-indent: -9999em;
      top: 40px;
      width: 32px;
      z-index: 99;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

      &.Nav__Collapse__Btn--active {
        cursor: pointer;
        height: 40px;
        position: absolute;
        right: 0;
        text-indent: -9999em;
        top: 40px;
        width: 32px;
        z-index: 99;

        span {
          background-color: transparent;
          width: 100%;

          &:after {
            background-color: ${colors.light.light500};
            width: 30px;
          }

          &:before {
            background-color: ${colors.light.light500};
            width: 30px;
            transform: translateY(6px) translateX(1px) rotate(45deg);
          }

          &:after {
            transform: translateY(-6px) translateX(1px) rotate(-45deg);
          }
        }
      }
    }

    span {
      background-color: ${colors.light.light500};
      display: block;
      height: 2px;
      left: 0;
      position: absolute;
      transition: all 0.4s;
      width: 26px;

      &:after,
      &:before {
        background-color: ${colors.light.light500};
        display: block;
        height: 2px;
        left: 0;
        position: absolute;
        transition: all 0.4s;
        width: 26px;
      }

      &:after {
        content: "";
      }

      &:before {
        content: "";
        top: -10px;
        transform-origin: 33% 100%;
        width: 30px;
        z-index: 10;
      }

      &:after {
        top: 10px;
        transform-origin: 33% 0;
        width: 22px;
      }
    }
  }

  .Nav__Sub {
    margin-top: 30px;
    text-align: right;

    ul {
      display: table;
      text-align: right;
      width: 100%;

      > li {
        display: inline-table;
        margin-left: 35px;

        a  {
          color: hsla(0, 0%, 100%, 0.7);
          font-weight: 700;
          text-transform: uppercase;

          &.active {
            color: ${colors.light.white};

            &.Btn {
              background: ${colors.light.white};
              color: ${colors.primary.main};
            }
          }
        }

        a:not(.Btn) {
          transition: color 0.2s ease-in-out;

          &:hover {
            color: ${colors.light.white};
          }
        }
      }
    }
  }

  .Btn.unfocusCTA {
    min-width: auto;
    padding: 0;
    border: none;

    &:hover {
      background: transparent !important;
      color: ${colors.light.white};
    }
  }

  /**
  * Responsive
  */
  @media (max-width: 991px) {
    background: ${colors.light.white};
    box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
    height: 80px;

    .Nav__Brand {
      margin-top: 30px;

      svg {
        fill: ${colors.light.light600};
      }
    }

    .Nav__Collapse {
      display: block;
    }

    .Nav__Sub {
      display: none;
      margin-top: 80px;
      position: absolute;

      &.Open {
        background: ${colors.light.white};
        display: block;
        left: 0;
        padding: 10px 0;
        width: 100%;

        ul > li {
          display: block;
          margin-left: 0;

          a {
            border-bottom: 1px solid ${colors.light.light100};
            color: ${colors.light.light500};
            display: inline-block;
            padding: 13px 0 13px 15px;
            text-align: left;
            width: 100%;

            &.active {
              color: ${colors.primary.main};
            }
          }

          &:last-child a {
            border: none;
            display: inherit;
            line-height: inherit;
            min-height: inherit;
            min-width: inherit;
          }
        }
      }
    }
  }

  @media (max-width: 1200px) {
    .Nav__Sub ul > li {
      margin-left: 15px;
    }
  }
`;

/**
 * Component
 */
const Header = ({ parentSlug }) => {
  // States
  const [isOpen, setIsOpen] = useState(false);

  const menu = [
    {
      title: "Home Page",
      link: "/",
    },
    {
      title: "Our Work",
      link: "/our-work",
    },
    {
      title: "Services",
      link: "/services",
    },
    {
      title: "Company",
      link: "/company",
    },
    {
      title: "Careers",
      link: "/careers",
    },
  ];

  return (
    <StyledHeader role="banner">
      <div className="Container Container--Header">
        {/* Logo */}
        <div className="Nav__Brand">
          <Link to="/">
            <svg
              width="170px"
              viewBox="0 0 209 21"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
            >
              <title>ClarkAgile</title>

              <g id="ClarkAgile">
                <path
                  d="M0.0290718292,11.0557341 C-0.406048952,5.49062543 4.10507505,0.117823064 9.40834201,0.00793375303 C12.937655,-0.0077647199 16.463249,0.0040091348 19.992562,0.00793375303 C20,1.73869039 20,3.46944704 19.996281,5.20020368 C16.6975448,5.227676 13.3988087,5.16488211 10.1000725,5.227676 C8.6645458,5.22375139 7.22530014,5.84384107 6.2918359,7.00945268 C4.61085647,8.87364634 4.59969952,12.1075318 6.27695997,13.9795747 C7.21786217,15.1648094 8.67942172,15.7888237 10.1298243,15.7809744 C13.4211225,15.8398437 16.7124208,15.7731252 20,15.8084468 C19.996281,17.5392034 20.007438,19.2660354 19.9888431,20.9967921 C16.4595301,20.9928674 12.933936,21.0085659 9.40462302,20.9928674 C4.48069213,20.8908274 0.155517185,16.2362301 0.0290718292,11.0557341 Z"
                  id="Path"
                ></path>
                <path
                  d="M21.0097076,20.9757196 C21.059431,13.9838131 20.9217356,6.9879873 21.0747305,0 C22.7653246,0.0627076819 24.4559186,0.0235153807 26.1465127,0.0391923012 C26.1541625,5.29096066 26.1465127,10.5466482 26.1541625,15.8023358 C30.4341959,15.8140935 34.7142293,15.7984166 38.9942627,15.8101743 C39.0019124,17.5385548 39.0019124,19.263016 38.9942627,20.9913965 C32.9968611,20.9913965 27.0032844,21.0188311 21.0097076,20.9757196 Z"
                  id="Path"
                ></path>
                <path
                  d="M48.1409312,4.14965726 C48.8181533,2.53035823 49.8302242,0.867930176 51.4781314,0.201390625 C52.8513874,-0.116195866 54.5068193,-0.167166538 55.6242358,0.871850996 C56.869572,1.87950196 57.4978837,3.43998868 58.1525317,4.88677158 C60.4362753,10.2582962 62.7275435,15.6259 65,20.9974246 C62.896849,20.9974246 60.7936981,21.0013454 58.6867848,20.9974246 C58.3218373,20.1466065 57.9380781,19.2997091 57.5693682,18.4528118 C55.2592883,18.448891 52.945446,18.4528118 50.635366,18.4528118 C51.3502116,16.6845216 52.0989183,14.9358355 52.8212886,13.1753869 C53.6678163,13.1636245 54.5105816,13.1557828 55.3571093,13.151862 C54.5933532,11.2659472 53.7994984,9.39179478 52.9981188,7.52156322 C51.0529864,11.991299 49.2244866,16.5120055 47.3094529,20.9935038 C45.2063019,21.0013454 43.103151,21.0013454 41,20.9974246 C43.3665151,15.3710466 45.7668914,9.76819358 48.1409312,4.14965726 Z"
                  id="Path"
                ></path>
                <path
                  d="M67.0056671,0.00256262608 C72.1664699,0.00256262608 77.3310508,-0.00528541629 82.4918536,0.00648664727 C86.4663518,0.00256262608 89.9459268,3.83240731 89.7683589,7.94870553 C89.8401417,10.805393 88.1438017,13.3952469 85.8618654,14.8589068 C87.5657615,16.9072459 89.3112161,18.9241928 91,20.9882279 C88.8994097,21.003924 86.8025974,20.9843039 84.7057851,21 C83.2021251,19.2930508 81.774026,17.5154692 80.2817001,15.7928239 C78.3737898,15.7967479 76.4658796,15.8006719 74.5579693,15.7928239 C74.5579693,14.0270143 74.5504132,12.2651288 74.5655254,10.4993193 C77.0854782,10.4875472 79.6016529,10.5228634 82.1216057,10.4953953 C83.4590319,10.5777997 84.7360094,9.34958109 84.698229,7.94478151 C84.8153483,6.48112161 83.5534829,5.21758678 82.1669421,5.22935885 C78.7780401,5.16265049 75.3929162,5.21758678 72.0040142,5.20581472 C72.0040142,10.4679271 72.0115702,15.7261155 72.0002361,20.9882279 C70.3341204,20.996076 68.6717828,20.996076 67.0056671,20.992152 C66.998111,13.9956222 66.998111,6.9990924 67.0056671,0.00256262608 Z"
                  id="Path"
                ></path>
                <path
                  d="M93.0055762,0.00650437442 C94.6710037,-0.00134710005 96.3327138,-0.00134710005 97.9944238,0.00257863718 C98.0018587,7.00216812 98.0018587,13.9978319 97.9944238,20.9974214 C96.3327138,21.0013471 94.6710037,21.0013471 93.0055762,20.9934956 C92.9981413,13.9978319 92.9981413,7.00216812 93.0055762,0.00650437442 Z"
                  id="Path"
                ></path>
                <path
                  d="M106.015184,0.858836238 C106.326464,0.494168204 106.599783,-0.117533014 107.184382,0.0197076441 C109.124187,0.0079441591 111.060195,0.0275499674 113,0.0275499674 C110.464208,3.52914733 107.901844,7.00721771 105.369848,10.5088151 C107.90564,14.0064913 110.460412,17.492404 113,20.9900802 C110.866594,20.9940013 108.736985,21.0175283 106.603579,20.9743955 C104.098156,17.4727982 101.539588,14.0064913 99,10.532342 C101.300434,7.27777787 103.707158,4.10555809 106.015184,0.858836238 Z"
                  id="Path"
                ></path>
                <path
                  d="M121.169515,4.08691165 C121.850714,2.48734279 122.859338,0.828966259 124.507762,0.193843331 C125.885212,-0.123718133 127.533637,-0.151161716 128.64764,0.89169445 C130.036381,2.04432495 130.672416,3.8399194 131.40254,5.46301133 C133.596676,10.6459281 135.817155,15.8131628 138,20.9960795 C135.896189,20.9960795 133.788615,21 131.684805,20.9960795 C131.319743,20.1453284 130.947154,19.2984978 130.578328,18.4516673 C128.26376,18.4438262 125.949192,18.4555878 123.634624,18.4477468 C124.349694,16.6835164 125.098636,14.9310476 125.82876,13.1707377 C126.671789,13.1628967 127.518582,13.1550557 128.365376,13.1511352 C127.586326,11.2614484 126.792222,9.37960272 125.994355,7.49775701 C124.082484,11.9906636 122.212012,16.4992523 120.311432,21 C118.207621,20.9960795 116.103811,21 114,20.9960795 C116.371021,15.3505423 118.78344,9.72460777 121.169515,4.08691165 Z"
                  id="Path"
                ></path>
                <path
                  d="M146.677727,0.612358931 C148.091273,0.0279378413 149.634014,-0.0269742074 151.134956,0.00832639537 C154.235637,0.0279378413 157.332518,0.00440410617 160.433199,0.0200932629 C160.440798,1.7498228 160.440798,3.47955233 160.436998,5.20928186 C156.929733,5.25242705 153.422468,5.13868066 149.922802,5.26419391 C147.236306,5.34656199 144.948794,7.96272888 145.142586,10.7279428 C145.176785,13.3597988 147.418699,15.6896386 149.964601,15.7602398 C152.6017,15.8661416 155.238798,15.7759289 157.875897,15.7994627 C157.875897,14.9169476 157.872097,14.0383548 157.872097,13.1558398 C155.341394,13.1440729 152.810692,13.1636843 150.279989,13.1479952 C150.268589,11.3868873 150.272389,9.62577949 150.279989,7.86467165 C154.516826,7.85682707 158.757463,7.85682707 162.9943,7.86467165 C163.0019,12.2419464 163.0019,16.6152988 162.9943,20.9925736 C158.529472,21.0004182 154.060843,21.0043404 149.596015,20.9925736 C144.705603,20.8827495 140.385169,16.4740964 140.058382,11.4574885 C139.545401,6.82526501 142.467489,2.18911919 146.677727,0.612358931 Z"
                  id="Path"
                ></path>
                <path
                  d="M165.002784,0.0233939844 C166.666048,0.0233939844 168.333024,0.0428889714 170,0 C169.974012,6.99870033 169.970299,14.0012997 170,21 C168.333024,20.953212 166.666048,20.972707 165.002784,20.976606 C164.999072,13.9896027 164.999072,7.00649833 165.002784,0.0233939844 Z"
                  id="Path"
                ></path>
                <path
                  d="M172.018859,1.67237571 C172.106758,1.15458534 171.831595,0.440662241 172.190836,0.036628841 C173.845636,-0.0457469008 175.504257,0.0405514953 177.162879,0.0130929147 C177.170523,5.27337242 177.159057,10.5336519 177.166701,15.7939314 C181.443193,15.8056994 185.719686,15.7900088 189.996178,15.8017768 C190,17.5277447 190.003822,19.2576353 189.992357,20.9875258 C183.999917,20.9718352 178.003655,21.0463656 172.011215,20.9482993 C172.049432,14.5229914 172.030324,8.09768357 172.018859,1.67237571 Z"
                  id="Path"
                ></path>
                <path
                  d="M191.002871,0.0025637504 C197.001076,0.0025637504 202.999281,-0.0052877352 208.993658,0.0064894932 C209.001314,1.73774207 209.001314,3.4650689 208.997486,5.19632148 C204.714147,5.2080987 200.430809,5.19239573 196.143643,5.20417296 C196.139815,8.73341574 196.139815,12.2665843 196.14747,15.795827 C200.430809,15.8076043 204.714147,15.7919013 208.997486,15.8036785 C209.001314,17.5349311 209.001314,19.2622579 208.993658,20.9935105 C202.999281,21.0052877 197.001076,20.9974362 191.002871,20.9974362 C190.999043,13.9978368 190.999043,7.00216316 191.002871,0.0025637504 Z"
                  id="Path"
                ></path>
                <path
                  d="M198.009684,8.01155291 C201.669715,7.99314536 205.333835,8.00050838 208.993866,8.0078714 C209.002045,9.66086974 209.002045,11.3101866 208.993866,12.9631849 C205.354282,12.992637 201.714699,12.9263698 198.075115,13 C197.936075,11.3396386 198.03831,9.67559578 198.009684,8.01155291 Z"
                  id="Path"
                ></path>
              </g>
            </svg>
          </Link>
        </div>

        {/* Menu */}
        <div className="Nav__Collapse">
          <b
            className={`Nav__Collapse__Btn ${
              isOpen ? "Nav__Collapse__Btn--active" : ""
            }`}
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            <span>Menu</span>
          </b>
        </div>

        {/* Navigation */}
        <nav className={`Nav__Sub ${isOpen ? "Open" : ""}`}>
          <ul>
            {menu.map((item, index) => {
              return (
                <li key={`item-${index}`}>
                  <Link to={item.link} activeClassName="active">
                    {item.title}
                  </Link>
                </li>
              );
            })}

            <li>
              <Link
                to="/contact"
                className={`Btn Btn--medium Btn--outline Btn--outline--white Display--block ${
                  parentSlug ? "unfocusCTA" : ""
                }`}
                activeClassName="active"
              >
                Contact
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </StyledHeader>
  );
};

export default Header;
