import React, { useState } from "react";

// Components
import CareerDetailNav from "./career-detail-nav";
import CareerDetailDescription from "./career-detail-description";
import CareerDetailForm from "./career-detail-form";
import CareerThankYou from "./career-thank-you";

/**
 * Component
 */
const CareerDetail = ({ title, jobDescription }) => {
  // States
  const [thankYou, setThankYou] = useState({
    stat: false,
    firstName: "",
  });

  //
  const { category } = jobDescription;

  return (
    <>
      {thankYou.stat === false ? (
        <>
          <CareerDetailNav category={category} />
          <CareerDetailDescription
            title={title}
            jobDescription={jobDescription}
          />
          <CareerDetailForm
            category={category}
            formName={title}
            setThankYou={setThankYou}
          />
        </>
      ) : (
        <CareerThankYou
          firstName={thankYou ? thankYou.firstName : ""}
        />
      )}
    </>
  );
};

export default CareerDetail;
