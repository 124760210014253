import React from "react";
import { graphql } from "gatsby";

// Components
import Layout from "../components/global/layout";
import Hero from "../components/sections/hero";
import LatestProjects from "../components/sections/latest-projects/latest-projects";
import Company from "../components/sections/company";
import Contact from "../components/sections/contact";
import Services from "../components/sections/services/services";
import Careers from "../components/sections/careers/careers";

// Work With us
const workWithUs = `
Interested in working with us?
See https://clarkagile.com/careers.

 _____ _         _   _____     _ _
|     | |___ ___| |_|  _  |___|_| |___
|   --| | .'|  _| '_|     | . | | | -_|
|_____|_|__,|_| |_,_|__|__|_  |_|_|___|
                          |___|
`;

// Child Page
import CareerDetail from "../components/sections/career-detail/career-detail";

console.log(workWithUs);

const Page = (props) => {
  const {
    data: { wpPage },
  } = props;

  return (
    <Layout parentSlug={wpPage.wpParent}>
      {(() => {
        switch (wpPage.slug) {
          case "home-page":
            return (
              <>
                <Hero slug={wpPage.slug} content={wpPage.hero} />
                <LatestProjects />
                <Company />
                <Contact />
              </>
            );
            break;
          case "our-work":
            return (
              <>
                <Hero slug={wpPage.slug} content={wpPage.hero} />
                <LatestProjects />
              </>
            );
            break;
          case "services":
            return (
              <>
                <Hero slug={wpPage.slug} content={wpPage.hero} />
                <Services />
              </>
            );
            break;
          case "company":
            return <Company />;
            break;
          case "careers":
            return (
              <>
                <Careers />
              </>
            );
            break;
          case "contact":
            return (
              <>
                <Hero slug={wpPage.slug} content={wpPage.hero} />
                <Contact />
              </>
            );
            break;

          default:
            break;
        }
      })()}

      {/* Child Pages */}
      {wpPage.wpParent ? (
        <CareerDetail
          title={wpPage.title}
          jobDescription={wpPage.careers}
        />
      ) : (
        ""
      )}
    </Layout>
  );
};

export default Page;

export const pageQuery = graphql`
  query($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      slug
      wpParent {
        node {
          id
          slug
        }
      }

      hero {
        headline
        shortDescription
        artName
      }

      careers {
        jobSummary
        whatYoullBeDoing
        whatYouShouldHave
        companyMission
        category
      }
    }
  }
`;
