import React from "react";
import styled from "styled-components";

// Utils
import colors from "../../../styles/utils/colors";

/**
 * Style
 */
const StyledServicesNav = styled.div`
  margin-top: 80;

  span {
    display: block;
  }

  ul {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: center;

    li a {
      border-radius: 6px;
      color: ${colors.primary.primary800};
      display: block;
      margin: 0 15px;
      padding: 15px;
      position: relative;
      text-align: center;
      top: 0;
      transition: top 0.3s;

      &:hover {
        background: ${colors.light.light150};
        top: -10px;
      }
    }
  }

  .Services__Nav__Picture {
    height: 60px;

    img {
      height: 50px;
      width: 50px;
    }
  }

  .Services__Nav__Title {
    margin: 10px 0;
  }

  .Services__Nav__Icon svg {
    fill: ${colors.primary.primary800};
    width: 20px;
  }

  /** Responsive */
  @media (max-width: 991px) {
    ul {
      flex-wrap: wrap;
      margin-top: 35px;

      li {
        margin: 0;
        width: 33.33333%;
      }
    }
  }
`;

/**
 * Component
 */
const ServicesNav = ({ services }) => {
  return (
    <StyledServicesNav className="Services__Nav">
      <ul>
        {services.map((service, index) => {
          return (
            <li key={`services-${index}`}>
              <a href="#">
                <span className="Services__Nav__Picture">
                  <img
                    src={`/images/services/${service.serviceIconName}.svg`}
                  />
                </span>
                <span className="Services__Nav__Title">
                  <h5>{service.serviceTitle}</h5>
                </span>
                <span className="Services__Nav__Icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 14 14"
                    id="down-arrow--svg"
                    width="100%"
                    height="100%"
                  >
                    <g
                      className="dcls-1"
                      id="dsvg_1"
                    >
                      <path
                        d="M13 7a6 6 0 1 1-6-6 6 6 0 0 1 6 6m1 0a7 7 0 1 0-7 7 7 7 0 0 0 7-7z"
                        id="dsvg_2"
                      ></path>
                    </g>
                    <g
                      stroke="null"
                      className="dcls-1"
                      id="dsvg_3"
                    >
                      <path
                        d="M7 9.36a.427.5 0 0 1-.298-.15L4.388 6.5a.427.5 0 0 1 .606-.71L7.3 8.51a.427.5 0 0 1-.3.85z"
                        id="dsvg_4"
                      ></path>
                      <path
                        d="M7 9.36a.427.5 0 0 1-.298-.85L9.016 5.8a.427.5 0 0 1 .606.71L7.3 9.21a.427.5 0 0 1-.3.15z"
                        id="dsvg_5"
                      ></path>
                    </g>
                  </svg>
                </span>
              </a>
            </li>
          );
        })}
      </ul>
    </StyledServicesNav>
  );
};

export default ServicesNav;
