/**
 *
 * @param {*} jobCategories
 */
export function getJobFields(jobCategories) {
  // Remove Dupcliated Keys
  let categories = {};

  jobCategories.edges.map((jobCategory) => {
    const category = jobCategory.node.careers.category;
    categories[category] = [];
  });

  //
  jobCategories.edges.map((jobCategory, index) => {
    const category = jobCategory.node.careers.category;

    // Remove Keys containing null values
    for (const key in jobCategory.node.careers) {
      if (!jobCategory.node.careers[key]) {
        delete jobCategory.node.careers[key];
      }
    }

    // Regroup SubCategories by Categories
    for (const jobField in categories) {
      if (category === jobField) {
        categories[jobField].push({
          title: jobCategory.node.title,
          slug: jobCategory.node.slug,
          categories: jobCategory.node.careers,
        });
      }
    }
  });

  //
  return categories;
}

/**
 *
 * @param {*} jobFields
 */
export function getCategories(jobFields) {
  const categories = [];
  for (const jobField in jobFields) {
    categories.push(jobField);
  }

  return categories;
}

/**
 * Encode Form Data
 * @param {*} data
 */
export function encode(data) {
  const formData = new FormData();

  for (const key of Object.keys(data)) {
    formData.append(key, data[key]);
  }

  return formData;
}

/**
 *
 * @param {*} files
 */
export function checkIfFileIsTooBig(file) {
  const fileSizeInMB = file ? file.size / 1024 / 1024 : null;

  return file && fileSizeInMB > 2 ? false : true;
}

/**
 *
 * @param {*} files
 */
export function checkIfFileHasCorrectType(file) {
  return file && file.type === "application/pdf" ? true : false;
}

/**
 * Regroup Projects By Type
 * @param {*} projects
 */
export function regroupProjectsByType(projects) {
  const projectsTypes = {
    website: [],
    webapp: [],
  };

  for (const projectType in projectsTypes) {
    projects.map((project) => {
      if (project.projectType === projectType) {
        projectsTypes[projectType].push(project);
      }
    });
  }

  return projectsTypes;
}

/**
 * Generate Career Form Initial Fields
 * @param {*} careerCategory
 */
export function getCareerFormFields(careerCategory) {
  /* Initial Values */
  let initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    location: "",
    linkedinProfile: "",
    resume: "",
  };

  // Extra Design Fields
  if (careerCategory === "Design") {
    initialValues["behance"] = "";
    initialValues["dribbble"] = "";
  }

  // Extra Engineering Fields
  else if (careerCategory === "Engineering") {
    initialValues["github"] = "";
    initialValues["website"] = "";
  }

  return initialValues;
}
