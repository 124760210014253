import React from "react";
import styled from "styled-components";
import { StaticQuery } from "gatsby";

// Utils
import colors from "../../../styles/utils/colors";

// Component
import Career from "./career";

/**
 * Style
 */
const StyledCareerCategory = styled.table`
  width: 100%;
  margin-bottom: 25px;
  border: 1px solid ${colors.light.light165};
  border-collapse: collapse;
  border-spacing: 0;

  box-shadow: 0 0 1rem rgb(0 0 0 / 5%);

  thead {
    background-color: ${colors.light.light100};
    text-align: center;
  }

  th {
    padding: 30px;
    background-color: inherit;
    color: ${colors.light.brown};
    text-transform: capitalize;
    font-size: 1.1rem;
  }

  a {
    color: ${colors.link};
    cursor: pointer;
    text-decoration: none;
    word-break: break-word;

    &:hover {
      border-bottom: none;
    }
  }

  /** Responsive */
  @media screen and (min-width: 48rem) {
    table-layout: fixed;
  }
`;

/**
 * Component
 */
const CareerCategory = ({ career }) => {
  const categoryTitle = career[0].categories.category;

  return (
    <StyledCareerCategory>
      {/* Head */}
      <thead>
        <tr>
          <th colSpan="4">{categoryTitle}</th>
        </tr>
      </thead>

      {/* Body */}
      <tbody>
        {career.map((careerInfos, index) => {
          return (
            <Career
              key={`career-${index}`}
              careerInfos={careerInfos}
            />
          );
        })}
      </tbody>
    </StyledCareerCategory>
  );
};

export default CareerCategory;
