import React, { useEffect } from "react";
import styled from "styled-components";
import { Link } from "gatsby";

/**
 * Style
 */
const StyledCareerThankYou = styled.div`
  padding: 5rem 6rem;
  max-width: 800px;
  margin: auto;

  h1 {
    margin-bottom: 25px;
  }

  p {
    font-size: 1.4rem;
    font-weight: 100;
  }

  em {
    display: block;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }

  b {
    display: block;
    margin-bottom: 10px;
  }

  .Btn {
    margin-top: 1rem;
    font-size: 1.1rem;
    padding: 0.4rem 2rem;
  }

  @media (max-width: 768px) {
    padding: 5rem 3rem;

    .Btn {
      padding: 0.4rem 0;
      width: 100%;
    }
  }
`;

/**
 * Component
 */
const CareerThankYou = ({ firstName }) => {
  // Scroll to the Top After the Form is submitted successfully
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <StyledCareerThankYou>
      <h1>Hi {`, ${firstName}`}! </h1>
      <p>
        Thanks for taking the time to apply for our position. We
        appreciate your interest in ClarkAgile.
      </p>
      <p>
        We're currently in the process of taking applications for this
        position. We will begin taking interviews in the next weeks.
        If you are selected to continue to the interview process, we
        will be in contact with you.
      </p>

      <em>Thank you,</em>

      <b>ClarkAgile</b>
      <Link
        to="/careers"
        className="Btn Btn--medium Btn--dark Display--inline"
      >
        Careers List
      </Link>
    </StyledCareerThankYou>
  );
};

export default CareerThankYou;
