import React from "react";
import styled from "styled-components";
import { navigate, Link } from "gatsby";

// Utils
import colors from "../../../styles/utils/colors";

/**
 * Style
 */
const StyledCareer = styled.tr`
  &:hover td {
    color: ${colors.primary.main};
  }

  td {
    background-color: ${colors.light.white};
    width: auto;
    color: ${colors.light.brown};
    transition: all 0.3s;
    padding: 30px;

    &:last-child {
      text-align: right;
    }

    &.Career__desktop {
      display: none;
    }
  }

  svg:not(:root) {
    overflow: hidden;
  }

  .Career__title {
    font-weight: 700;
    line-height: 1.28571429;
    letter-spacing: normal;
    font-size: 0.875rem;
    color: ${colors.primary.main};
    margin-bottom: 1rem;
  }

  p.Career__title {
    margin-bottom: 0.5rem;
  }

  .Career__location {
    font-weight: 400;
    line-height: 1.28571429;
    letter-spacing: normal;
    font-size: 0.875rem;
    color: ${colors.light.brown};
    margin: 0;
  }

  .Arrow {
    width: 1rem;
    height: 20px;
    vertical-align: top;
    fill: currentColor;
  }

  .Hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    border: 0;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0 0 0 0);
  }

  /** Responsive */
  @media screen and (min-width: 48rem) {
    &:not(.c-filter-header) {
      cursor: pointer;
    }

    td.Career__desktop {
      display: table-cell;
    }

    .Career__mobile {
      display: none;
    }
  }

  @media screen and (min-width: 25rem) {
    .Career__title {
      font-size: calc(0.875rem + (0 * (100vw - 400px) / 624));
    }

    .Career__location {
      font-size: calc(0.875rem + (0 * (100vw - 400px) / 624));
    }
  }
`;

/**
 * Component
 */
const Career = ({ careerInfos }) => {
  return (
    <StyledCareer
      className="c-filter-items"
      onClick={() => {
        navigate(`/careers/${careerInfos.slug}`);
      }}
    >
      {/* Mobile */}
      <td className="Career__mobile">
        <p className="Career__title">
          <Link to={`/careers/${careerInfos.slug}`}>
            {careerInfos.title} -{" "}
            {careerInfos.categories.category == "Design"
              ? careerInfos.categories.designSubCategory
              : careerInfos.categories.engineeringSubCategory}
          </Link>
        </p>
        <p className="Career__location">
          {careerInfos.categories.city}
        </p>
      </td>

      {/*  */}
      <td className="Career__desktop">
        {careerInfos.title}
        <span className="Career__mobile">
          -{" "}
          {careerInfos.categories.category === "Design"
            ? careerInfos.categories.designSubCategory
            : careerInfos.categories.engineeringSubCategory}
        </span>
      </td>

      {/*  */}
      <td className="Career__desktop">
        <span className="job_title">
          {careerInfos.categories.category === "Design"
            ? careerInfos.categories.designSubCategory
            : careerInfos.categories.engineeringSubCategory}
        </span>
      </td>

      {/*  */}
      <td className="Career__desktop">
        {careerInfos.categories.city}
      </td>

      {/*  */}
      <td className="Career__desktop">
        <Link to={`/careers/${careerInfos.slug}`}>
          <strong>
            Apply &nbsp;
            <span className="Hidden">
              for{" "}
              {careerInfos.categories.category === "Design"
                ? careerInfos.categories.designSubCategory
                : careerInfos.categories.engineeringSubCategory}{" "}
              - UI/UX in {careerInfos.categories.city}
            </span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="-288 379 35 35"
              width="20"
              height="20"
              className="Arrow svg-replaced"
              shapeRendering="geometricPrecision"
            >
              <path d="M-256.5 395.5l-9.9-10.1c-.6-.6-1.5-.6-2 0-.6.6-.6 1.5 0 2.1l7.5 7.6h-22.4c-.8 0-1.4.7-1.4 1.5s.6 1.5 1.4 1.5h22.4l-7.5 7.6c-.6.6-.6 1.5 0 2.1.6.6 1.5.6 2 0l9.9-10.1c.5-.7.5-1.7 0-2.2z"></path>
            </svg>
          </strong>
        </Link>
      </td>
    </StyledCareer>
  );
};

export default Career;
