import React from "react";
import styled from "styled-components";
import { StaticQuery } from "gatsby";

// Utils
import colors from "../../../styles/utils/colors";

// Helpers
import {
  getJobFields,
  getCategories,
} from "../../../helpers/helpers";

// Components
import CareerCategory from "./career-category";

/**
 * Style
 */
const StyledCareers = styled.section`
  tr {
    border-bottom: 1px solid ${colors.light.light165};
  }
`;

/**
 * Component
 */
const Careers = () => {
  return (
    <StaticQuery
      query={graphql`
        {
          allWpPage(
            filter: {
              wpParent: { node: { slug: { eq: "careers" } } }
            }
          ) {
            edges {
              node {
                title
                slug

                careers {
                  category
                  designSubCategory
                  engineeringSubCategory
                  city
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        const jobFields = getJobFields(data.allWpPage);
        const categories = getCategories(jobFields);

        return (
          <StyledCareers className="Section Careers">
            <div className="Container">
              {/* Headline */}
              <div class="Section__Headline Text--center">
                <h2 class="Text--small Opacity--40 FontWeight--bold Text--uppercase">
                  Careers
                </h2>
                <p class="Text--xlarge FontWeight--bold">
                  List of Career Opportunities
                </p>
              </div>

              {/* List */}
              <div className="Careers__items">
                {categories.map((jobField, index) => {
                  return (
                    <CareerCategory
                      key={`jobfield-${index}`}
                      career={jobFields[jobField]}
                    />
                  );
                })}
              </div>
            </div>
          </StyledCareers>
        );
      }}
    ></StaticQuery>
  );
};

export default Careers;
