import React, { useState } from "react";
import styled from "styled-components";
import { Formik } from "formik";
import * as Yup from "yup";
import { Link, StaticQuery } from "gatsby";

// Utils
import colors from "../../styles/utils/colors";

// Helpers
import { encode } from "../../helpers/helpers";

/**
 * Style
 */
const StyledContact = styled.section`
  .Contact {
    h5 {
      font-size: 20px;
      margin-bottom: 30px;
    }

    input,
    textarea {
      appearance: none;
      border: 1px solid ${colors.light.light160};
      border-radius: 4px;
      margin-bottom: 30px;
      outline: none;
      padding: 15px 20px;
      transition: all 0.3s ease 0s;
      width: 100%;
    }

    textarea {
      line-height: 1.9em;
    }

    input:focus,
    textarea:focus {
      border: 1px solid ${colors.primary.main};
      padding-left: 30px;
    }

    input[type="text"] {
      height: 50px;
    }

    label {
      display: none;
    }

    ul {
      display: flex;
      flex-wrap: wrap;

      li {
        position: relative;

        &:first-child {
          margin-right: 20px;
          flex: 25%;
        }

        &:nth-child(2) {
          flex: 25%;
        }

        &:last-child {
          flex: 100%;
        }
      }
    }

    .button {
      background: transparent;
      border: 1px solid ${colors.primary.primary800};
      border-radius: 100px;
      color: ${colors.primary.primary800};
      cursor: pointer;
      line-height: 35px;
      min-height: 35px;
      min-width: 200px;
      padding: 0 30px;
      text-align: center;
      transition: all 0.3s ease 0s;

      &:hover {
        background: ${colors.primary.primary800};
        color: ${colors.light.white};
      }
    }

    .validation_message {
      background: ${colors.messages.error};
      border-radius: 5px;
      color: ${colors.light.white};
      font-size: 12px;
      font-weight: 700;
      padding: 0 10px;
      position: absolute;
      right: 15px;
      top: 15px;
    }

    .validation_error {
      display: none;
    }

    .Contact__Form {
      width: 65%;
    }

    .Contact__GetInTouch {
      padding-right: 40px;
      width: 35%;
    }
  }

  .Contact__thank-you {
    max-width: 500px;
    margin: auto;
    text-align: center;

    h2 {
      font-size: 2rem;
      margin-bottom: 2rem;
    }

    a {
      display: block;
      margin-top: 25px;
    }
  }

  .Form__error {
    display: block;
    margin-top: -1rem;
    margin-bottom: 1rem;
    margin-left: 1rem;
  }

  @media (max-width: 991px) {
    .Contact {
      ul li {
        &:first-child {
          margin-right: 0;
          flex: 100%;
        }

        &:nth-child(2) {
          flex: 100%;
        }
      }

      .Contact__GetInTouch {
        width: 55%;
      }
      .Contact__Form {
        width: 45%;
      }
    }
  }

  @media (max-width: 768px) {
    .Contact {
      .Contact__GetInTouch {
        margin-bottom: 30px;
        width: 100%;
      }

      .Contact__Form {
        width: 100%;
      }
    }
  }

  @media (max-width: 576px) {
    .Contact .button {
      width: 100%;
    }
  }
`;

/**
 * Component
 */
const Contact = () => {
  // States
  const [thankYou, setThankYou] = useState(false);

  // Validation Shcema
  const ApplyFormSchema = Yup.object().shape({
    email: Yup.string()
      .required("Email is Required")
      .email("Must be a valid Email"),
    fullName: Yup.string()
      .required("Name is Required")
      .min(3, "Name must be at least 3 characters"),
    message: Yup.string()
      .required("Message is Required")
      .min(100, "Message must at least 100 characters")
      .max(500),
  });

  /**
   *
   */
  return (
    <StaticQuery
      query={graphql`
        {
          wpPage(slug: { eq: "contact" }) {
            contact {
              description
            }
          }
        }
      `}
      render={(data) => {
        const {
          wpPage: {
            contact: { description },
          },
        } = data;

        return (
          <StyledContact className="Contact Section Section--gray">
            <div className="Container">
              {/* Contact Form & Get In Touch */}

              {thankYou === true ? (
                <div className="Contact__thank-you">
                  <h2>Thank you for getting in touch! </h2>
                  <p>
                    We appreciate you contacting us/ ClarkAgile. One
                    of our team member will get back in touch with you
                    soon!
                  </p>

                  <em>Have a great day!</em>

                  <Link to="/services">&#8592; Our Services</Link>
                </div>
              ) : (
                <>
                  {/* Title */}
                  <div className="Section__Headline Text--center">
                    <h2 className="Text--small Opacity--40 FontWeight--bold Text--uppercase">
                      Contact
                    </h2>
                    <p className="Text--xlarge FontWeight--bold">
                      Let's builth something together
                    </p>
                  </div>
                  <div className="Grid Contact">
                    {/* Get In Touch */}
                    <div className="Grid__Item Contact__GetInTouch">
                      <h5>Get In Touch</h5>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: description,
                        }}
                      />
                    </div>

                    <Formik
                      initialValues={{
                        email: "",
                        fullName: "",
                        message: "",
                      }}
                      validationSchema={ApplyFormSchema}
                      onSubmit={(values, actions) => {
                        const encodedFormData = encode({
                          "form-name": event.target.getAttribute(
                            "name",
                          ),
                          ...values,
                        });

                        fetch("/", {
                          method: "POST",
                          headers: {
                            "Content-Type":
                              "application/x-www-form-urlencoded",
                          },
                          body: encodedFormData,
                        })
                          .then(() => {
                            setThankYou(true);
                          })
                          .catch((error) => console.log(error))
                          .finally(() =>
                            actions.setSubmitting(false),
                          );
                      }}
                    >
                      {({
                        values,
                        dirty,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                      }) => {
                        return (
                          <div className="Grid__Item Contact__Form">
                            <form
                              name="Contact"
                              method="POST"
                              data-netlify={true}
                              onSubmit={handleSubmit}
                              encType="application/x-www-form-urlencoded"
                              noValidate
                            >
                              <ul>
                                {/* Email */}
                                <li>
                                  <label htmlFor="email">Email</label>
                                  <input
                                    type="email"
                                    name="email"
                                    placeholder="Email"
                                    //
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.email}
                                  />

                                  {/* Error Message */}
                                  {errors.email && touched.email && (
                                    <div className="Form__error">
                                      {errors.email}
                                    </div>
                                  )}
                                </li>

                                {/* Name */}
                                <li>
                                  <label htmlFor="fullName">
                                    Name
                                  </label>
                                  <input
                                    type="text"
                                    name="fullName"
                                    minLength="5"
                                    maxLength="15"
                                    placeholder="Name"
                                    //
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.fullName}
                                  />

                                  {/* Error Message */}
                                  {errors.fullName &&
                                    touched.fullName && (
                                      <div className="Form__error">
                                        {errors.fullName}
                                      </div>
                                    )}
                                </li>
                                {/* Message */}
                                <li>
                                  <label htmlFor="Message">
                                    Message
                                  </label>

                                  <textarea
                                    name="message"
                                    cols="5"
                                    rows="10"
                                    placeholder="Your Message"
                                    //
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.message}
                                  ></textarea>

                                  {/* Error Message */}
                                  {errors.message &&
                                    touched.message && (
                                      <div className="Form__error">
                                        {errors.message}
                                      </div>
                                    )}
                                </li>
                              </ul>

                              <button className="button">Send</button>
                            </form>
                          </div>
                        );
                      }}
                    </Formik>
                  </div>
                </>
              )}
            </div>
          </StyledContact>
        );
      }}
    ></StaticQuery>
  );
};

export default Contact;
