// Global Libraries
import React from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";

// Components
import Header from "./header";
import Footer from "./footer";

// Styles
import { Reset } from "../../styles/reset";

/**
 * Component
 */
const Layout = ({ children, parentSlug }) => {
  /**
   *
   */
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={(data) => {
        const siteTitle = data.site.siteMetadata.title;

        return (
          <>
            <Helmet>
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
              />

              {/* HTML Meta Tags */}
              <meta
                name="description"
                content="ClarkAgile is a Web Agency specializing in creating innovative web applications that bring your ideas to life."
              />

              {/* Facebook Meta Tags */}
              <meta property="og:url" content="https://clarkagile.com/" />
              <meta property="og:type" content="website" />
              <meta property="og:title" content="ClarkAgile | Web Agency" />
              <meta
                property="og:description"
                content="ClarkAgile is a Web Agency specializing in creating innovative web applications that bring your ideas to life."
              />
              <meta
                property="og:image"
                content="https://clarkagile.clarkapi.com/wp-content/uploads/2023/08/clarkagile-og.jpg"
              />

              {/* Twitter Meta Tags */}
              <meta name="twitter:card" content="summary_large_image" />
              <meta property="twitter:domain" content="clarkagile.com" />
              <meta property="twitter:url" content="https://clarkagile.com/" />
              <meta name="twitter:title" content="ClarkAgile | Web Agency" />
              <meta
                name="twitter:description"
                content="ClarkAgile is a Web Agency specializing in creating innovative web applications that bring your ideas to life."
              />
              <meta
                name="twitter:image"
                content="https://clarkagile.clarkapi.com/wp-content/uploads/2023/08/clarkagile-og.jpg"
              />
            </Helmet>
            <Header siteTitle={siteTitle} parentSlug={parentSlug} />
            <main>{children}</main>
            <Footer />

            <Reset />
          </>
        );
      }}
    />
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
