import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

// Utils
import colors from "../../styles/utils/colors";

/**
 * Style
 */
const StyledHero = styled.section`
  background: linear-gradient(
      90deg,
      ${colors.primary.primary500},
      ${colors.primary.primary700} 54%,
      ${colors.primary.primary600}
    )
    50% no-repeat;
  padding-top: 52px;
  position: relative;
  width: 100%;

  .Container {
    display: flex;
    flex-direction: row-reverse;
  }

  .Hero__Artwork {
    flex: 2;
    position: relative;
    text-align: right;

    &.contact {
      img {
        width: 100%;
        bottom: 0;
      }
    }

    img {
      bottom: -36px;
      position: absolute;
      right: 0;
    }
  }

  .Hero__TextContent {
    color: ${colors.light.white};
    flex: 1;
    font-weight: 400;
    min-width: 440px;
    padding: 10px 0 80px;

    h1,
    p {
      margin-bottom: 30px;
    }

    h1 {
      font-size: 36px;
      letter-spacing: 1.4px;
      line-height: 46px;
    }

    p {
      font-size: 16px;
      line-height: 28px;
    }

    .btn {
      display: inline-block;
    }
  }

  @media (max-width: 991px) {
    &.Hero--interne {
      padding-top: 30px;

      .Hero__Artwork {
        display: none;
      }

      .Hero__TextContent {
        margin-bottom: 10px;
        padding: 0;
      }
    }

    .Container {
      flex-direction: column-reverse;
    }

    .Hero__Artwork,
    .Hero__TextContent {
      flex: 1;
      min-width: auto;
    }

    .Hero__Artwork {
      text-align: center;

      img {
        bottom: 0;
        position: relative;
        width: 100%;
      }
    }
  }

  @media (max-width: 420px) {
    .Hero__Artwork {
      display: none;
    }
  }
`;

/**
 * Component
 */
const Hero = ({ content, slug }) => {
  const { headline, shortDescription, artName } = content;

  return (
    <StyledHero className="Hero">
      {/* Content */}
      <div className="Container">
        {/* Hero Artwork */}
        <div className={`Hero__Artwork ${artName}`}>
          <img src={`/images/heros/${artName}.svg`} alt={headline} />
        </div>

        {/* Hero Content */}
        <div className="Hero__TextContent">
          <h1>{headline}</h1>
          <p>{shortDescription}</p>

          {slug === "home-page" || slug === "contact" ? (
            <Link
              to="/our-work"
              className="Btn Btn--medium Btn--white Display--inline"
            >
              Check our Work
            </Link>
          ) : (
            ""
          )}
        </div>
      </div>
    </StyledHero>
  );
};

export default Hero;
