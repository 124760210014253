// Components
import React from "react";
import { createGlobalStyle, css } from "styled-components";

// Utils
import colors from "./utils/colors";

// Fonts
import { latinFonts } from "./utils/fonts";

/**
 * Reset
 */
const reset = css`
  *,
  :after,
  :before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  html {
    -ms-overflow-style: scrollbar;
    -ms-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-text-size-adjust: 100%;

    font-family: sans-serif;
    line-height: 1.15;
  }

  body,
  html {
    font-family: ${latinFonts.body};
    font-size: 16px;
    font-weight: 400;

    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    -ms-text-size-adjust: 100%;

    background-color: ${colors.light.white};
  }

  a,
  abbr,
  acronym,
  address,
  applet,
  article,
  aside,
  b,
  big,
  blockquote,
  body,
  center,
  cite,
  code,
  dd,
  del,
  dfn,
  div,
  div pre,
  dl,
  dt,
  em,
  figcaption,
  figure,
  font,
  footer,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  header,
  html,
  i,
  iframe,
  img,
  ins,
  kbd,
  li,
  nav,
  object,
  ol,
  p,
  q,
  s,
  samp,
  section,
  small,
  span,
  strike,
  strong,
  sub,
  sup,
  tt,
  u,
  ul,
  var {
    border: 0;
    margin: 0;
    outline: 0;
    padding: 0;
  }

  article,
  aside,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  main,
  nav,
  section {
    display: block;
  }

  [tabindex="-1"]:focus {
    outline: 0 !important;
  }

  hr {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    height: 0;
    overflow: visible;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 0.5rem;
    margin-top: 0;
  }

  p {
    margin-bottom: 1rem;
    margin-top: 0;
    line-height: 1.8em;
  }

  abbr[data-original-title],
  abbr[title] {
    border-bottom: 0;
    cursor: help;
    text-decoration: underline;
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
  }

  address {
    font-style: normal;
    line-height: inherit;
  }

  address,
  dl,
  ol,
  ul {
    margin: 0;
    padding: 0;
  }

  ul li {
    list-style: none;
  }

  dl,
  ol,
  ul {
    margin-top: 0;
  }

  ol ol,
  ol ul,
  ul ol,
  ul ul {
    margin-bottom: 0;
  }

  dt {
    font-weight: 700;
  }

  dd {
    margin-bottom: 0.5rem;
    margin-left: 0;
  }

  blockquote {
    margin: 0 0 1rem;
  }

  dfn {
    font-style: italic;
  }

  b,
  strong {
    font-weight: bolder;
  }

  small {
    font-size: 80%;
  }

  sub,
  sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }

  sub {
    bottom: -0.25em;
  }

  sup {
    top: -0.5em;
  }

  a {
    -webkit-text-decoration-skip: objects;
    background-color: transparent;
    color: ${colors.primary.main};
  }

  a:hover {
    color: #105ad0;
  }

  a,
  a:hover {
    text-decoration: none;
  }

  a:not([href]):not([tabindex]),
  a:not([href]):not([tabindex]):focus,
  a:not([href]):not([tabindex]):hover {
    color: inherit;
    text-decoration: none;
  }

  a:not([href]):not([tabindex]):focus {
    outline: 0;
  }

  code,
  kbd,
  pre,
  samp {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas,
      Liberation Mono, Courier New, monospace;
    font-size: 1em;
  }

  pre {
    -ms-overflow-style: scrollbar;
    margin-bottom: 1rem;
    margin-top: 0;
    overflow: auto;
  }

  figure {
    margin: 0 0 1rem;
  }

  img {
    border-style: none;
  }

  img,
  svg {
    vertical-align: middle;
  }

  svg {
    overflow: hidden;
  }

  table {
    border-collapse: collapse;
  }

  caption {
    caption-side: bottom;
    color: #6c757d;
    padding-bottom: 0.75rem;
    padding-top: 0.75rem;
    text-align: left;
  }

  th {
    text-align: inherit;
  }

  label {
    display: inline-block;
    margin-bottom: 0.5rem;
  }

  button {
    border-radius: 0;
  }

  button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    margin: 0;
  }

  button,
  input {
    overflow: visible;
    &:focus {
      outline: none;
    }
  }

  button,
  select {
    text-transform: none;
  }

  [type="reset"],
  [type="submit"],
  button,
  html [type="button"] {
    -webkit-appearance: button;
  }

  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner,
  button::-moz-focus-inner {
    border-style: none;
    padding: 0;
  }

  * {
    -webkit-tap-highlight-color: rgb(33 150 243 / 35%);
  }

  input[type="checkbox"],
  input[type="radio"] {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
  }

  input[type="date"],
  input[type="datetime-local"],
  input[type="month"],
  input[type="time"] {
    -webkit-appearance: listbox;
  }

  textarea {
    overflow: auto;
    resize: vertical;
  }

  fieldset {
    border: 0;
    margin: 0;
    min-width: 0;
    padding: 0;
  }

  legend {
    color: inherit;
    display: block;
    font-size: 1.5rem;
    line-height: inherit;
    margin-bottom: 0.5rem;
    max-width: 100%;
    padding: 0;
    white-space: normal;
    width: 100%;
  }

  progress {
    vertical-align: baseline;
  }

  [type="number"]::-webkit-inner-spin-button,
  [type="number"]::-webkit-outer-spin-button {
    height: auto;
  }

  [type="search"] {
    -webkit-appearance: none;
    outline-offset: -2px;
  }

  [type="search"]::-webkit-search-cancel-button,
  [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  ::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit;
  }

  output {
    display: inline-block;
  }

  summary {
    cursor: pointer;
    display: list-item;
  }

  template {
    display: none;
  }

  [hidden] {
    display: none !important;
  }
`;

/**
 * Grid
 */
const grid = css`
  .Grid {
    display: flex;
    padding: 0;

    .Grid__Item {
      display: inline-block;
      box-sizing: border-box;
      vertical-align: top;
      width: 100%;
    }
  }

  /** Responsive */
  @media (max-width: 991px) {
    .Grid {
      flex-wrap: wrap;
    }
  }
`;

/**
 * Container
 */
const container = css`
  .Container {
    width: 100%;

    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
  }

  /** Responsive */
  @media (min-width: 576px) {
    .Container {
      max-width: 540px;
    }
  }

  @media (min-width: 768px) {
    .Container {
      max-width: 720px;
    }
  }

  @media (min-width: 992px) {
    .Container {
      max-width: 960px;
    }
  }

  @media (min-width: 1200px) {
    .Container {
      max-width: 1140px;
    }
  }
`;

/**
 * Section
 */
const section = css`
  .Section {
    padding: 80px 0;

    &.Section--dark {
      background: linear-gradient(
          90deg,
          ${colors.primary.primary500},
          ${colors.primary.primary700} 54%,
          ${colors.primary.primary600}
        )
        50% no-repeat;
      color: ${colors.light.white};

      .Section__Headline {
        h2 {
          color: ${colors.light.white};
        }

        p {
          color: ${colors.light.white};
          line-height: 1.4em;
        }
      }
    }

    &.Section--gray {
      background-color: #f3f5f9;
    }

    &.Section--wave {
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYwMCIgaGVpZ2h0PSIyMDAiIHZpZXdCb3g9IjAgMCAxNjAwIDIwMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48dGl0bGU+Z3JheS13YXZlPC90aXRsZT48cGF0aCBkPSJNMTYwMCAxODcuMTY2QzgwMS43NjYgMjMwLjY2NCA3MDYuMDkgNTEuMTgzIDAgNzkuNzA0VjBoMTYwMHYxODcuMTY2eiIgZmlsbD0iI0ZGRiIgZmlsbC1ydWxlPSJldmVub2RkIi8+PC9zdmc+);
      background-position: 100% -125px;
      background-repeat: no-repeat;
      color: rgba(3, 27, 78, 0.7);
    }

    .Section__Headline {
      margin-bottom: 60px;

      p {
        line-height: 1.2em;
      }
    }
  }

  @media (max-width: 991px) {
    .Section__Headline {
      h2 {
        font-size: 1rem;
      }
      p {
        font-size: 1.6rem;
      }
    }
  }
`;

/**
 * Text
 */
const text = css`
  .Text--uppercase {
    text-transform: uppercase;
  }

  .Text--xlarge {
    color: ${colors.primary.primary800};
    font-size: 36px;
    letter-spacing: -0.9px;
  }

  .Text--small {
    font-size: 14px;
  }

  .FontWeight--bold {
    font-weight: 700;
  }

  .Opacity--40 {
    opacity: 0.4;
  }

  .Text--center {
    text-align: center;
  }

  .Display--inline {
    display: inline-block;
  }

  .Display--block {
    display: block;
  }
`;

/**
 * Button
 */
const button = css`
  .Btn {
    border-radius: 100px;
    text-align: center;
    transition: all 0.3s ease 0s;

    /* Sizes */
    &.Btn--medium {
      line-height: 35px;
      min-height: 35px;
      min-width: 140px;
      padding: 0 30px;
    }

    /* Outline */
    &.Btn--outline {
      border: 1px solid ${colors.light.white};
    }

    &.Btn--outline--white {
      border-color: ${colors.light.white};
      color: ${colors.light.white};

      &.Btn--outline--white:hover {
        background: ${colors.light.white};
        color: ${colors.primary.main};
      }
    }

    &.Btn--outline--dark {
      border-color: ${colors.primary.primary800};
      color: ${colors.primary.primary800};
      &:hover {
        background: ${colors.primary.primary800};
        color: ${colors.light.white};
      }
    }

    /* Primary */
    &.Btn--white {
      background: ${colors.light.white};
      color: ${colors.primary.main};

      &:hover {
        background-color: ${colors.primary.main};
        color: ${colors.light.white};
      }
    }

    &.Btn--dark {
      background: ${colors.primary.primary800};
      color: ${colors.light.white};

      &:hover {
        background-color: ${colors.primary.main};
        color: ${colors.light.white};
      }
    }
  }

  .Btn__not-allowed {
    cursor: not-allowed;
    opacity: 0.3;
  }
`;

/**
 * Tabs
 */
const tabs = css`
  .Tabs__Nav {
    display: flex;
    flex-wrap: wrap;
    font-size: 18px;
    justify-content: center;
    position: relative;

    &:before {
      background: ${colors.light.light200};
      content: "";
      height: 1px;
      left: 0;
      position: absolute;
      top: 37px;
      width: 100%;
    }

    .Tabs__Nav__Item {
      display: inline-flex;
      margin-bottom: 10px;
    }

    .Tabs__Nav__Item__Link {
      display: inline-block;
      position: relative;
      height: 37px;
      padding: 0 7px;
      margin-right: 32px;

      color: ${colors.light.light700};

      font-weight: 600;
      line-height: 37px;
      text-align: center;
      text-transform: capitalize;

      cursor: pointer;

      &.Tabs__Nav__Item__Link--active,
      &:hover {
        color: ${colors.primary.primary800};

        &:before {
          background: ${colors.primary.main};
          bottom: 0;
          content: "";
          height: 2px;
          left: 0;
          position: absolute;
          right: 0;
        }
      }
    }
  }

  .Tabs__Content .Tabs__Content__Pane {
    display: none;

    &.Tabs__Content__Pane--active {
      display: block;
    }
  }

  /** Responsive */
  @media (max-width: 991px) {
    .Tabs__Nav__Item {
      width: 25%;

      .Tabs__Nav__Item__Link {
        border-bottom: 1px solid ${colors.light.light200};
        margin: 0;
        width: 100%;
      }
    }
  }

  @media (max-width: 576px) {
    .Tabs__Nav__Item {
      width: 50%;
    }
  }
`;

/**
 * Label
 */
const label = css`
  .Label {
    display: inline-block;
    padding: 4px 8px;

    background: ${colors.primary.primary800};
    color: ${colors.light.white};
    font-size: 12px;

    text-transform: uppercase;
  }
`;

/**
 * Form States
 */
const form_states = css`
  .Form__error {
    color: ${colors.messages.error};
    margin-top: 1rem;
  }
`;

/**
 * Main
 */
const GlobalStyle = createGlobalStyle`
  ${reset}
  ${grid}
  ${container}
  ${text}
  ${section}
  ${button}
  ${tabs}
  ${label}
  ${form_states}
`;

/**
 * Reset Component
 */
export const Reset = () => {
  return <GlobalStyle />;
};
