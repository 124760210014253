import React from "react";
import styled from "styled-components";

// Utils
import colors from "../../../styles/utils/colors";

/**
 * Style
 */
const StyledCareerDetailDescription = styled.section`
  padding-bottom: 45px;
  padding: 0 10vw !important;
  font-size: 1.1rem;

  h1 {
    margin-bottom: 2rem;
    font-size: 2.3rem;
    text-align: center;
  }

  h2 {
    margin: 2rem 0;
  }

  ul {
    padding: 0 1rem;
  }

  ul li {
    margin-left: 1.2rem;
    line-height: 1.6rem;
    margin-bottom: 1rem;
    list-style: initial;
  }

  p {
    padding-top: 1rem;
  }

  .Btn {
    display: inline-block;
    white-space: nowrap;
    flex-basis: auto;
    width: 100%;

    margin-bottom: 25px;
    padding: 7px 0 !important;
    border-radius: 6px;
  }

  .apply-mobile {
    display: block;
  }

  .CareerDetail__mission {
    margin-top: 3rem;
    padding-top: 2rem;
    border-top: 1px solid ${colors.light.light165};
  }

  /** Responsive */
  @media screen and (min-width: 48rem) {
    .apply-mobile {
      display: none;
    }
  }
`;

/**
 * Component
 */
const CareerDetailDescription = ({ title, jobDescription }) => {
  const {
    jobSummary,
    whatYoullBeDoing,
    whatYouShouldHave,
    companyMission,
  } = jobDescription;

  return (
    <StyledCareerDetailDescription className="Container CareerDetail__description">
      <a
        href="#apply"
        className="Btn Btn--medium Btn--dark apply-mobile"
      >
        Apply
      </a>

      <h1>{title}</h1>
      {/* Summary */}
      <div
        dangerouslySetInnerHTML={{
          __html: jobSummary,
        }}
      />
      {/* What You'll Be Doing */}
      <h2>What you'll be doing</h2>
      <div
        dangerouslySetInnerHTML={{
          __html: whatYoullBeDoing,
        }}
      />
      {/* What you should have */}
      <h2>What you should have</h2>
      <div
        dangerouslySetInnerHTML={{
          __html: whatYouShouldHave,
        }}
      />
      {/* Company Mission */}
      <div
        className="CareerDetail__mission"
        dangerouslySetInnerHTML={{
          __html: companyMission,
        }}
      />
    </StyledCareerDetailDescription>
  );
};

export default CareerDetailDescription;
