import React from "react";
import styled from "styled-components";
import { Link, StaticQuery } from "gatsby";

// Utils
import colors from "../../styles/utils/colors";

/**
 * Style
 */
const StyledCompany = styled.section`
  .Company {
    padding-top: 35px;

    &.Section .Section__Headline {
      margin-bottom: 20px;
    }
  }

  .Company__Services {
    padding-right: 30px;
    text-align: center;

    p {
      text-align: initial;
    }

    .Company__Services__List {
      display: flex;
      flex-wrap: wrap;
      margin: 45px 0;

      > li {
        color: #031b4e;
        flex: 33%;
        margin-bottom: 20px;
        padding: 0 10px;

        > div {
          background: linear-gradient(
              90deg,
              ${colors.light.light170},
              ${colors.light.white} 54%,
              ${colors.light.light170}
            )
            50% no-repeat;
          border-radius: 5px;
          box-shadow: 0 2px 9px -1px rgba(0, 0, 0, 0.2);
          height: 120px;
          padding-top: 20px;

          &:hover {
            background: ${colors.light.light100};
          }
        }

        img {
          height: 45px;
          margin-bottom: 15px;
          width: 45px;
        }
      }
    }
  }

  .Company__Picture {
    text-align: center;

    img {
      width: 100%;
    }
  }

  /** Responsive */
  @media (max-width: 991px) {
    .Company__Picture {
      order: 0;
    }

    .Company__Services {
      margin-bottom: 30px;
      order: 1;
      padding-right: 0;

      .Company__Services__List {
        margin: 20px 0;

        > li {
          flex: 50%;
        }
      }
    }
  }

  @media (max-width: 576px) {
    .Company__Services .Btn {
      width: 100%;
    }
  }
`;

/**
 * Component
 */
const Company = () => {
  return (
    <StaticQuery
      query={graphql`
        {
          servicesList: wpPage(slug: { eq: "services" }) {
            services {
              ... on WpPage_Services {
                services {
                  serviceTitle
                  serviceIconName
                }
              }
            }
          }

          company: wpPage(slug: { eq: "company" }) {
            company {
              description
            }
          }
        }
      `}
      render={(data) => {
        const {
          servicesList: { services: servicesList },
          company: {
            company: { description },
          },
        } = data;

        return (
          <StyledCompany className="Section Section--dark">
            <div className="Container">
              {/* Title */}
              <div className="Section__Headline Text--center">
                <h2 className="Text--small Opacity--40 FontWeight--bold Text--uppercase">
                  Company
                </h2>
                <p className="Text--xlarge FontWeight--bold">
                  We Help you to succeed
                </p>
              </div>

              {/* Company Detail */}
              <div className="Grid Company">
                {/* Content */}
                <div className="Grid__Item Company__Services">
                  {/* Company Description & Services */}
                  <p
                    className="Company__Services__Description"
                    dangerouslySetInnerHTML={{ __html: description }}
                  />

                  {/* Services */}
                  <ul className="Company__Services__List">
                    {servicesList.services.map((service, key) => {
                      return (
                        <li key={`service${key}`}>
                          <div>
                            <img
                              src={`/images/services/${service.serviceIconName}.svg`}
                            />
                            <h5>{service.serviceTitle}</h5>
                          </div>
                        </li>
                      );
                    })}
                  </ul>

                  {/* Button */}
                  <Link
                    to="/services"
                    className="Btn Btn--medium Btn--outline Btn--outline--white Display--inline"
                  >
                    Services Detail
                  </Link>
                </div>

                {/* // Picture */}
                <div className="Grid__Item Company__Picture">
                  <img src="/images/cli.svg" alt="" />
                </div>
              </div>
            </div>
          </StyledCompany>
        );
      }}
    ></StaticQuery>
  );
};

export default Company;
