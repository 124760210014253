import React from "react";
import styled from "styled-components";
import Img from "gatsby-image";

// Utils
import colors from "../../../styles/utils/colors";

/**
 * Style
 */
const StyledProject = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 30px 0 70px;
  padding: 3rem;

  box-shadow: 1px 1px 27px rgba(0, 0, 0, 0.1);
  transform: scale(1) translateY(0);
  transition: all 0.3s ease-in-out;

  opacity: 1;

  &:nth-child(2n) {
    .Project__Preview__Picture--two-thirds {
      order: 0;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  &:hover
    .Project__Preview__Status
    .Project__Preview__Status__Message {
    left: 0 !important;
    opacity: 1;
    right: 0;
  }

  .Grid {
    margin: 0 0 0 -2em;

    .Grid__Item {
      padding-left: 2em;
    }
  }

  /* Infos */
  .Project__Infos {
    display: flex;
    flex: 1 1 50%;
    flex-basis: 35%;
    flex-direction: column;
    max-width: 500px;

    .Project_Infos_Content {
      flex: 1 1 100%;
      padding-right: 30px;

      .Project_Infos_Content__Description {
        margin: 20px 0;
      }

      .Project_Infos_Content__Location {
        display: flex;
        margin: 30px 0;

        font-size: 14px;
        font-weight: 600;
        text-transform: capitalize;

        > div {
          border-radius: 5px;
          background: ${colors.light.light150};
        }

        .Project_Infos_Content__Location__Flag {
          display: flex;
          width: 100px;
          align-items: center;
          justify-content: center;

          img {
            max-width: 20px;
            margin-right: 5px;
          }
        }

        .Project_Infos_Content__Location__OwnerLogo {
          display: block;
          width: 50px;
          margin-left: 5px;
          padding: 8px;

          .gatsby-image-wrapper {
            max-width: inherit !important;
            max-height: inherit !important;
          }
        }
      }
    }

    .Project_Infos_Technologies {
      flex: 1 1 100%;

      .Project_Infos_Technologies__Logos {
        display: flex;

        img {
          display: block;
          margin: 6px;
          max-height: 40px;
          max-width: 40px;
        }
      }
    }
  }

  /* Preview */
  .Project__Preview {
    flex: 1 1 100%;
    flex-basis: 65%;

    img {
      height: auto;
      width: 100%;
    }

    .DropDown {
      .DropDown__Content {
        display: block;
      }
    }

    .Project__Preview__Picture--two-thirds {
      background: #eaeaea;
      h3 {
        margin: 0 0 0.5em;
        line-height: 1.4em;
        font-size: 1.3rem;
      }

      p {
        margin: 0 auto 2em;
      }

      .DropDown__Content {
        padding: 35px 15px 0 0;
      }
    }

    .Project__Preview__Status {
      overflow: hidden;
      position: relative;

      &.Project__Preview__Status--inprogress
        .Project__Preview__Status__Message {
        background: ${colors.primary.main};
      }

      &.Project__Preview__Status--online
        .Project__Preview__Status__Message {
        background: ${colors.secondary.main};
      }

      .Project__Preview__Status__Message {
        height: 100%;
        left: -100%;
        position: absolute;
        right: 100%;
        top: 0;
        transition: 0.3s;
        width: 100%;

        a {
          color: ${colors.light.white};

          &:hover {
            text-decoration: underline;
          }
        }

        > span {
          bottom: 0;
          box-sizing: border-box;
          color: ${colors.light.white};
          font-family: Georgia, Times New Roman, Times, serif;
          font-size: 26px;
          font-weight: 300;
          height: 30px;
          left: 0;
          margin: auto;
          position: absolute;
          right: 0;
          text-align: center;
          top: 0;
          width: 70%;
        }
      }

      .Project__Preview__Status__Picture span {
        &:last-child {
          display: block;
        }
      }

      .Project__Preview__Status__Message {
        display: none;
      }
    }
  }

  /** Responsive */
  @media (max-width: 991px) {
    margin-bottom: 20px;
    flex-wrap: wrap;
    padding: 0;

    .Grid {
      flex-wrap: wrap;
      margin: 0;

      .Grid__Item {
        padding: 0;
      }
    }

    .Btn {
      width: 100%;
    }

    .DropDown {
      .DropDown__Btn {
        display: block;
      }
    }

    .Project__Infos {
      flex-basis: 100%;

      .Project_Infos_Content {
        padding: 2rem 2rem 0 2rem;
      }

      .Project_Infos_Technologies {
        background: ${colors.light.light100};
        display: flex;
        justify-content: center;
        border-radius: 6px;
        padding: 1rem;
      }
    }

    .Project__Preview {
      .Project__Preview__Picture--two-thirds {
        .DropDown__Content {
          padding: 2rem;
        }
      }

      .Project__Preview__Status
        .Project__Preview__Status__Picture
        span {
        &:first-child {
          display: block;
        }

        .gatsby-image-wrapper {
          max-width: initial !important;
        }
      }
    }
  }

  @media (max-width: 576px) {
    flex-wrap: wrap;
  }
`;

/**
 * Project
 */
const Project = ({ project }) => {
  return (
    <StyledProject>
      {/* Infos */}
      <div className="Project__Infos">
        {/* Content */}
        <div className="Project_Infos_Content">
          <div>
            <h2>{project.projectCompanyName}</h2>
            <small className="Label">{project.projectCategory}</small>
          </div>

          <p className="Project_Infos_Content__Description">
            {project.projectDescription}
          </p>

          <div className="Project_Infos_Content__Location">
            <div className="Project_Infos_Content__Location__Flag">
              <img
                src={`/images/flags/${project.projectCountry}.svg`}
                alt="Morocco Flag"
              />
              <span>{project.projectCountry}</span>
            </div>

            <div className="Project_Infos_Content__Location__OwnerLogo">
              <Img
                fluid={
                  project.projectSmallLogo.localFile.childImageSharp
                    .fluid
                }
              />
            </div>
          </div>
        </div>

        {/* Technologies */}
        <div className="Project_Infos_Technologies">
          <div className="Project_Infos_Technologies__Logos">
            {project.projectTechnologies.map((technology, index) => {
              return (
                <img
                  key={`technology${index}`}
                  src={`/images/technologies/${technology}.png`}
                  alt=""
                />
              );
            })}
          </div>
        </div>
      </div>

      {/* Preview & Use Cases */}
      <div className="Project__Preview">
        <div className="Grid Project__Preview__Picture">
          {/*  */}
          <div className="Grid__Item Project__Preview__Picture--one-third">
            <div className="Project__Preview__Status Project__Preview__Status--inprogress">
              <span className="Project__Preview__Status__Picture">
                <span>
                  <Img
                    fluid={
                      project.projectPreviewDesktop.localFile
                        .childImageSharp.fluid
                    }
                  />
                </span>
              </span>
              <span className="Project__Preview__Status__Message">
                <span>Implementation in Progress</span>
              </span>
            </div>
          </div>

          {/*  */}
          <div className="Grid__Item DropDown Project__Preview__Picture--two-thirds">
            <div className="DropDown__Content">
              <h3>{project.projectUseCaseHeadline}</h3>
              <p>{project.projectUseCasesDetail}</p>

              <a
                href={project.projectUrl.url}
                target="_blank"
                className="Btn Btn--medium Btn--outline Btn--outline--dark Display--inline"
              >
                Preview
              </a>
            </div>
          </div>
        </div>
      </div>
    </StyledProject>
  );
};

export default Project;
