import React, { useState } from "react";
import styled from "styled-components";
import { StaticQuery } from "gatsby";

// Helpers
import { regroupProjectsByType } from "../../../helpers/helpers";

// Components
import Project from "./project";

/**
 * Style
 */
const StyledLatestProjects = styled.section`
  .Project:nth-child(2n) .Project__Preview__Picture--one-third {
    order: 1;
  }

  .Project:nth-child(2n) .Project__Preview__Picture--two-thirds {
    order: 0;
  }

  h2,
  h3 {
    font-size: 25px;
  }

  h2 {
    margin-bottom: 10px;
  }

  .WorkInProgress {
    max-width: 400px;
    margin: auto;
    padding-top: 3rem;

    img {
      width: 100%;
    }
  }

  /** Responsive */
  @media (max-width: 991px) {
    .Project:nth-child(2n) {
      .Project__Preview__Picture--one-third,
      .Project__Preview__Picture--two-thirds {
        order: 0;
      }
    }
  }
`;
/**
 * Component
 */
const LatestProjects = () => {
  return (
    <StaticQuery
      query={graphql`
        {
          wpPage(slug: { eq: "our-work" }) {
            title
            work {
              projectsList {
                projectCompanyName
                projectCategory
                projectType
                projectDescription
                projectTechnologies
                projectCountry
                projectSmallLogo {
                  localFile {
                    childImageSharp {
                      fluid(
                        maxWidth: 70
                        maxHeight: 70
                        quality: 100
                      ) {
                        ...GatsbyImageSharpFluid
                        ...GatsbyImageSharpFluidLimitPresentationSize
                      }
                    }
                  }
                }

                projectUseCaseHeadline
                projectUseCasesDetail

                projectUrl {
                  url
                }

                projectPreviewDesktop {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 376, quality: 100) {
                        ...GatsbyImageSharpFluid
                        ...GatsbyImageSharpFluidLimitPresentationSize
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        // States
        const [projectType, setProjectType] = useState("website");

        // Data
        const {
          wpPage: { work },
        } = data;

        // Regrouped Data
        const projectsList = regroupProjectsByType(work.projectsList);

        return (
          <StyledLatestProjects>
            <div className="Section Container">
              {/* Title */}
              <div className="Section__Headline Text--center">
                <h2 className="Text--small Opacity--40 FontWeight--bold Text--uppercase">
                  Latest Projects
                </h2>
                <p className="Text--xlarge FontWeight--bold">
                  Check Our Lastest Projects
                </p>
              </div>

              {/* Projects */}
              <div className="Tabs">
                {/* Tabs Nav Tabs__Nav__Item__Link--active */}
                <ul className="Tabs__Nav" role="tablist">
                  {Object.keys(projectsList).map((type) => {
                    return (
                      <li className="Tabs__Nav__Item">
                        <span
                          className={`Tabs__Nav__Item__Link ${
                            projectType === type
                              ? "Tabs__Nav__Item__Link--active "
                              : ""
                          }`}
                          role="tab"
                          onClick={(e) => {
                            const selectedProjectType = e.target.innerText
                              .toLowerCase()
                              .slice(0, -1);

                            setProjectType(selectedProjectType);
                          }}
                        >
                          {`${type}s`}
                        </span>
                      </li>
                    );
                  })}
                </ul>

                {/* Tabs Content */}
                <div className="Tabs__content">
                  <div
                    className="Tabs__Content__Pane"
                    id="websites"
                    role="tabpanel"
                  >
                    {projectsList[projectType].length > 0 ? (
                      <div className="Projects">
                        {projectsList[projectType].map(
                          (project, index) => {
                            return (
                              <Project
                                key={`project-${index}`}
                                project={project}
                              />
                            );
                          },
                        )}
                      </div>
                    ) : (
                      <div className="WorkInProgress">
                        <img
                          src="/images/in-progress.png"
                          alt="Web Apps In Progress"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </StyledLatestProjects>
        );
      }}
    ></StaticQuery>
  );
};

export default LatestProjects;
